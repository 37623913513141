@media (max-width: 989px) {
  .wrapper--1jN0I {
    position: absolute;
    width: 100%;
    overflow: scroll; } }

.persons--2Lg4f {
  color: hsla(0, 0%, 15%, 1); }
  @media (max-width: 989px) {
    .persons--2Lg4f {
      overflow: scroll; } }
  .persons__content--2OUSg {
    flex-wrap: nowrap !important; }
    @media (max-width: 989px) {
      .persons__content--2OUSg {
        max-width: none !important; } }
  .persons__person--5IQrK {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    width: 265px;
    min-width: 265px;
    overflow: hidden;
    border: 1px solid hsla(0, 0%, 93%, 1);
    border-radius: 6px; }
  .persons__person-content-container--1Spkl {
    display: flex;
    align-items: stretch; }
  .persons__person-content---Tjbz {
    display: flex;
    flex-direction: column; }
  .persons__stats--3rlfV {
    display: flex;
    flex-flow: row nowrap;
    align-items: center; }
  .persons__star--2xgb5 {
    --svg-primary-color: var(--color-secondary);
    position: relative;
    top: -1px;
    margin-right: 5px; }
